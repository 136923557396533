import React, { ReactElement } from "react";

import Image from "next/image";
export default function HomeWarrantyRight(): ReactElement {
    return (
        <section className="flex flex-col lg:flex-row  lg:gap-20 gap-6 lg:mt-32 mt-16  lg:mb-48 mb-24">
            <div className="max-w-[621px] mx-auto lg:mx-0 ">
                <div className="flex gap-4 ">
                    <span className="block w-[6px] h-[42px] bg-[#F6CEBE]"></span>
                    <h2 className="font-bold lg:text-3xl text-xl lg:leading-[45px]  ">
                        Is a Home Warranty Right for You?
                    </h2>
                </div>

                <p className="mt-5 font-light lg:text-lg  text-base lg:leading-8 text-[#505050]">
                    A home warranty is an ideal service for homeowners seeking
                    to safeguard their household appliances and systems from
                    unexpected breakdowns and repairs. It is particularly
                    beneficial for those who lack expertise in repairs or find
                    it challenging to allocate time for maintenance tasks. With
                    a home warranty, homeowners can have peace of mind knowing
                    that their home systems are covered in case of unforeseen
                    breakdowns.
                </p>
            </div>
            <div className="w-[260px] h-[150px] md:w-[447px] md:min-w-[447px] md:h-[255px]  relative  mx-auto lg:mx-0 ">
                <Image
                    src={
                        "/homewarranty.center/assets/images/homeWarrantyRightImg.png"
                    }
                    alt="Image"
                    layout="fill"
                />
            </div>
        </section>
    );
}
